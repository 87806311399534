import React from "react"
import {designSystem} from "../components/layout";
import {StaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const InsideShopImage = () => (
    <StaticQuery
        query={graphql`
      query {
        fileName: file(relativePath: { eq: "productsOnDisplay.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
        render={data => <Img fluid={data.fileName.childImageSharp.fluid} style={{
            borderLeft: `${designSystem.layout.decorativeBorder} solid ${designSystem.palette.mainPurple}`,
            borderRadius: designSystem.layout.borderRadiusStandard
        }}/>}
    />
);

export default InsideShopImage
