import React from "react"

import SEO from "../components/seo"
import InsideShopImage from "../components/insideShopImage"
import styles from "./about.module.css";

import Header from "../components/header";
import Layout, {designSystem} from "../components/layout";
import headerStyles from "../components/header.module.css";
import ContactSection from "../components/contactSection";
import SocialMedia from "../components/socialMedia";


const About = () => (
    <Layout>
        <div
            className={styles.all}
        >
            <SEO title="About"/>
            <div
                className={headerStyles.header}
            >
                <Header
                    headerTextColour={designSystem.palette.black}
                    backgroundColour={designSystem.palette.white}
                    backgroundColourSmallScreen={designSystem.palette.white}
                    showSmallHeading={true}
                />
            </div>
            />
            <SocialMedia/>
            <div
                className={styles.content}
            >
                <div
                    className={styles.imageSmallScreen}
                >
                    <InsideShopImage
                    />
                </div>

                <div
                    className={styles.contentColumns}
                >
                    <div
                        style={{
                            width: '100%'
                        }}
                        className={styles.aboutText}
                    >
                        <h3>Meet the Lavender Ladies</h3>

                        <blockquote>"Hi, our names are Annie and Lori and this is how the Bodalla Lavender Shop was
                            created."
                        </blockquote>

                        <div
                            className={styles.pColumns}
                        >
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr',
                                    gridTemplateRows: '1fr 1fr 1fr',
                                }}
                            >
                                <p>
                                    8 years ago when Annie and I were in our mid fifties we were both working. Annie a
                                    gardener with ARTHRITIS and myself a shop assistant working hard for an employer
                                    that did not know how to say THANKS!
                                </p>
                                <p>
                                    Feeling unappreciated after a long day on my feet I sat down to have a little
                                    Chardonay and started thinking we could be doing something better.At the time my
                                    brother owned a Lavender farm in WA which had a small Lavender Shop attached using a
                                    few SECRET Lavender recipes.
                                </p>
                                <p>
                                    I walked over to Annies house up the road and said "How
                                    about we open a Lavender Shop." Nearby to where we live is the small quaint town of
                                    Bodalla. At the time it boasted
                                    a pub, bakery, cheese factory and Dairy Shed Cafe.
                                </p>
                            </div>
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr',
                                    gridTemplateRows: '1fr 1fr 1fr'
                                }}
                            >
                                <p>
                                    We approached the owners of the
                                    Dairy Shed Sandra and Robert as they owned a couple of potential shop fronts.
                                    Luckily for us they were and continue to be encouraging and supportive. The Bodalla
                                    Lavender Shop was born...
                                </p>
                                <p>
                                    We rented what had been an old repair shop and originally a saddlery it was a little
                                    run down but we made it our own. Annie and I started with about 10 Lavender recipes
                                    and now boast to creating over 30
                                    products using only the best and natural of ingredients.
                                </p>
                                <p>
                                    Together with our love of vintage, antique and quirky old wares our shop has grown
                                    into what it is today. Annie and I are now in our sixties and continue to run the
                                    shop with a lot of love and enthusiasm
                                </p>
                                <div
                                    style={{
                                        width: '100px',
                                        height: '2px',
                                        backgroundColor: designSystem.palette.mainPurple,
                                        margin: '0 auto'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        className={styles.rightColumn}
                        style={{
                            width: '100%',
                            marginTop: '70px'
                        }}>
                        <div
                            style={{
                                maxWidth: '512px'
                            }}>
                            <InsideShopImage/>
                            <ContactSection/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default About;